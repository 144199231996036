import React from 'react';
import CashFlowModelingInput from 'features/drilldown/cashflows/CashFlowModelingInput';
import { Field, Form, Formik } from 'formik';
import { CashFlowsValidValues } from './configurations/cashflow-inputs';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  cdr: Yup.number().test(
    'cdr',
    'Value between 0-100',
    (value) => value !== undefined && value >= 0 && value <= 100
  ),
  cpr: Yup.number().test(
    'cpr',
    'Value between 0-100',
    (value) => value !== undefined && value >= 0 && value <= 100
  ),
  lsr: Yup.number().test(
    'lsr',
    'Value between -5-100',
    (value) => value !== undefined && value >= -5 && value <= 100
  ),
  daysDelay: Yup.number().test(
    'daysDelay',
    'Value >= 0',
    (value) => value !== undefined && value >= 0
  ),
  servicingRate: Yup.number().min(0).max(5).notRequired(),
});

export type CashFlowModelingInputsProps = {
  formValues: CashFlowsValidValues;
  onSubmit: (validatedFormValues: CashFlowsValidValues) => void;
  withServicingRate?: boolean;
};

/**
 * `CashFlowModelingInputs` is a React functional component that renders a form with fields for cash flow modeling.
 * It uses Formik for form management and Yup for form validation.
 *
 * Props:
 * - `formValues`: An object of type `CashFlowsValidValues` that contains the initial values for the form fields.
 * - `onSubmit`: A function that is called when the form is submitted. It receives the validated form values as its argument.
 *
 * The form contains the following fields:
 * - `cdr`: A `CashFlowModelingInput` field for the Conditional Default Rate (CDR). It accepts values between 0 and 100.
 * - `cpr`: A `CashFlowModelingInput` field for the Conditional Prepayment Rate (CPR). It accepts values between 0 and 100.
 * - `lsr`: A `CashFlowModelingInput` field for the Loss Severity Rate (LSR). It accepts values between -5 and 100.
 * - `daysDelay`: A `CashFlowModelingInput` field for the number of days delay. It accepts values greater than or equal to 0.
 *
 * Each field is validated on blur and the form is submitted. The validation rules for each field are defined in the `validationSchema` object.
 */
export const CashFlowModelingInputs: React.FC<CashFlowModelingInputsProps> = ({
  formValues,
  onSubmit,
  withServicingRate,
}) => {
  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form>
          <div style={{ display: 'flex', gap: '16px', color: '#e8e9dc', }}>
            <Field
              name="cdr"
              as={CashFlowModelingInput}
              fieldName="CDR"
              onBlur={handleSubmit}
            />
            <Field
              name="cpr"
              as={CashFlowModelingInput}
              fieldName="CPR"
              onBlur={handleSubmit}
            />
            <Field
              name="lsr"
              as={CashFlowModelingInput}
              fieldName="LSR"
              onBlur={handleSubmit}
            />
            <Field
              name="daysDelay"
              as={CashFlowModelingInput}
              fieldName="Delay"
              unit="d"
              onBlur={handleSubmit}
            />
            {
              withServicingRate && (
                <Field
                  name="servicingRate"
                  as={CashFlowModelingInput}
                  fieldName="Servicing Rate"
                  onBlur={handleSubmit}
                  onSubmit={handleSubmit}
                />
              )
            }
          </div>
          {/* This button is hidden because the form is submitted programmatically */}
          <button type="submit" style={{ display: 'none' }}></button>
        </Form>
      )}
    </Formik>
  );
};
