import { gql, useMutation, useQuery } from '@apollo/client';
import { ToggleButton } from 'common-ui';
import toast from 'react-hot-toast';

import './AppPrefs.scss';
import { DisableChat } from './__generated__/DisableChat';
import { EnableChat } from './__generated__/EnableChat';
import { Spinner } from 'baseui/spinner';

const ENABLE_CHAT_MUTATION = gql`
  mutation EnableChat {
    enableChat {
      id
      is_chat_enabled
    }
  }
`;

const DISABLE_CHAT_MUTATION = gql`
  mutation DisableChat {
    disableChat {
      id
      is_chat_enabled
    }
  }
`;

const IS_CHAT_ENABLED_QUERY = gql`
  query IsChatEnabled {
    user {
      id
      company {
        id
        is_chat_enabled
      }
    }
  }
`;

const AppPrefs = () => {
  const { data, loading } = useQuery(IS_CHAT_ENABLED_QUERY);
  const [enableChat] = useMutation<EnableChat>(ENABLE_CHAT_MUTATION);
  const [disableChat] = useMutation<DisableChat>(DISABLE_CHAT_MUTATION);

  const handleToggle = async (checked: boolean) => {
    if (checked) {
      await enableChat();
      toast.success('Thanks for enabling Chat for your organization. Please be aware that any counterparty must also have enabled Chat before you will be able to use it.');
    } else {
      await disableChat();
    }
  };

  return (
    <div className='app__prefs'>
      <table>
        <tr>
          <td className='heading w-[210px] flex items-start'>Enable chat for your company</td>
          <td className='value'>
            {loading ? (
              <div className='flex justify-center items-center'>
                <Spinner size={34} />
              </div>
            ) : (
              <div className='flex flex-col items-end'>
                <ToggleButton
                  initialState={data?.user?.company?.is_chat_enabled}
                  checkedLabel='On'
                  uncheckedLabel='Off'
                  onChange={handleToggle}
                />
                {data?.user?.company?.is_chat_enabled && (
                  <p className='mt-2 text-xs text-gray-500 max-w-[400px]'>
                    Thanks for enabling Chat for your organization. Please be aware that any counterparty must also have enabled Chat before you will be able to use it.
                  </p>
                )}
              </div>
            )}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AppPrefs;
