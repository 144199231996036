import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { styled, css } from 'style/ORSNNTheme';
import { ReactComponent as ChevronDown } from 'assets/svgs/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/svgs/chevron-up.svg';
import { IndeterminateCheckbox } from 'common-ui/bool-inputs';

type StyledDivProps = {
  expanded?: boolean;
};

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.div<StyledDivProps>`
  align-items: center;
  border: solid 1px ${(props) => props.theme.color.white};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  ${(props) =>
    props.expanded &&
    css`
      background-color: ${(props) => props.theme.color.white};
      border: none;
      border-bottom: solid 1px ${(props) => props.theme.color.black};
      color: ${(props) => props.theme.color.black};
    `}
`;

const Overlay = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border: solid 1px ${(props) => props.theme.color.black};
  color: black;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

type DropdownListProps = {
  maxHeight?: number;
};

const DropdownList = styled.div<DropdownListProps>`
  max-height: ${(props) => props.maxHeight ?? 200}px;
  overflow-y: auto;
`;

const DropdownItem = styled.label`
  :hover {
    background-color: ${(props) => props.theme.color.grey100};
    color: ${(props) => props.theme.color.brandMainPink};
  }
  cursor: pointer;
  padding: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
`;

const CheckboxContainer = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const ClickableSpan = styled.span`
  cursor: pointer;
`;

export type Item<T> = {
  key: string;
  text?: string | null;
  selected?: boolean;
  data?: T;
};

type State<T> = {
  expanded: boolean;
  items: Item<T>[];
};

type RowProps<T> = {
  item: Item<T>;
  onClick: Function;
  multiselect?: boolean;
};

const ItemRow = <T extends object | string>({
  item,
  onClick,
  multiselect = false,
}: RowProps<T>) => {
  return (
    <DropdownItem onClick={() => !multiselect && onClick(item)}>
      <ClickableSpan>{item.text ?? item.key}</ClickableSpan>
      {multiselect ? (
        <CheckboxContainer>
          <IndeterminateCheckbox
            checked={item.selected}
            onChange={() => onClick(item)}
            onClick={() => onClick(item)}
          />
        </CheckboxContainer>
      ) : (
        <Fragment />
      )}
    </DropdownItem>
  );
};

type Props<T> = {
  items: Item<T>[];
  placeholder?: string;
  onlyUsePlaceholder?: boolean;
  multiselect?: boolean;
  onItemSelect: (items: Item<T>[]) => void;
  maxHeight?: number;
  continuousUpdate?: boolean;
};

const DeprecatedDropdown = <T extends object | string>({
  items,
  placeholder,
  multiselect,
  onItemSelect,
  maxHeight,
  continuousUpdate = false,
  onlyUsePlaceholder = false,
}: Props<T>): JSX.Element => {
  const [state, setState] = useState<State<T>>({
    expanded: false,
    items,
  });

  const onSelectRef = useRef(onItemSelect);

  useEffect(() => {
    if ((multiselect && continuousUpdate) || !state.expanded) {
      onSelectRef.current(state.items.filter((item) => item.selected));
    }
  }, [state.items, onSelectRef]);

  const toggleItem = useCallback(
    (item: Item<T>) =>
      setState((s) => ({
        ...s,
        items: multiselect
          ? s.items.map((i) =>
            i === item ? { ...item, selected: !item.selected } : i
          )
          : s.items.map((i) => ({ ...i, selected: i === item })),
        expanded: multiselect ?? false,
      })),
    [setState, multiselect]
  );

  const itemRows = state.items.map((item: Item<T>) => (
    <ItemRow
      key={item.key}
      item={item}
      multiselect={multiselect}
      onClick={(item: Item<T>) => toggleItem(item)}
    />
  ));

  const selectedItems = state.items.filter((item) => item.selected);
  const displayText =
    onlyUsePlaceholder || selectedItems.length < 1
      ? placeholder
      : selectedItems.map((item) => item.text ?? item.key).join(', ');

  return (
    <DropdownContainer>
      <OutsideClickHandler
        onOutsideClick={() =>
          state.expanded && setState((state) => ({ ...state, expanded: false }))
        }
      >
        <DropdownButton
          expanded={false}
          onClick={() => setState((state) => ({ ...state, expanded: true }))}
        >
          {displayText}
          <ChevronDown />
        </DropdownButton>
        {state.expanded ? (
          <Overlay>
            <DropdownButton
              expanded={true}
              onClick={() =>
                setState((state) => ({
                  ...state,
                  expanded: multiselect ?? false,
                }))
              }
            >
              {displayText}
              <ChevronUp fill={'black'} />
            </DropdownButton>
            <DropdownList maxHeight={maxHeight}>{itemRows}</DropdownList>
          </Overlay>
        ) : null}
      </OutsideClickHandler>
    </DropdownContainer>
  );
};

export default DeprecatedDropdown;
