import { gql } from '@apollo/client';

const DealCarve = gql`
    fragment CarveTableDealCarve on DealCarve {
      id
      name
      carve_summary {
        unpaidBalance: current_balance_cents
        originalBalance: original_balance_cents
        loanCount: loan_count
        averageBalance: wa_current_balance_cents
        averageOriginalBalance: wa_original_balance_cents
        netCoupon: wa_coupon
        servicingRate: wa_servicing_rate
        averageAge: wa_age_months
        averageMaturity: wa_remaining_loan_terms_months
        fico: wa_borrower_credit_score
        dti: wa_dti
        ltv: wa_ltv
        states: strat_state {
          stateCode: key
          loanCount: t_count
        }
      }
    }
  `;

const CarveTableFragments = {
  DealCarve,
  PlaceBidCard: gql`
    fragment CarveTablePlaceBidCard on PlaceBidCard {
      carves {
        ...CarveTableDealCarve
      }
    }
    ${DealCarve}
  `,
};

export default CarveTableFragments;
