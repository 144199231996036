import { useLazyQuery, useMutation } from '@apollo/client';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, KIND } from 'baseui/button';
import { StyledBody } from 'baseui/card';
import { Input } from 'baseui/input';
import { ListItem, ListItemLabel } from 'baseui/list';
import CarveDiffTable from '../CarveDiffTable';
import FilterInputs from 'features/core/filter/FilterInputs';
import {CheckboxWithLabel} from 'common-ui';
import { FilterContextProvider } from 'context/FilterContext';
import { ACCEPT_BID } from 'mutation/acceptBid';
import { REJECT_BID } from 'mutation/rejectBid';
import { SAVE_SELLER_BID } from 'mutation/saveSellerBid';
import {
  AcceptBid,
  AcceptBidVariables,
} from 'mutation/__generated__/AcceptBid';
import {
  RejectBid,
  RejectBidVariables,
} from 'mutation/__generated__/RejectBid';
import {
  SaveSellerBid,
  SaveSellerBidVariables,
} from 'mutation/__generated__/SaveSellerBid';
import prettyBytes from 'pretty-bytes';
import { CALCULATE_BID_PRICE } from 'query/calculateBidPrice';
import {
  CalculateBidPrice,
  CalculateBidPriceVariables,
} from 'query/__generated__/CalculateBidPrice';
import { useEffect, useMemo, useState } from 'react';
import { styled, theme } from 'style/ORSNNTheme';
import { DealActionOptionType, Emphasis } from '__generated__/globalTypes';
import { GET_DEAL_TIMELINE } from '../../fragments';
import BidSummarySection from '../BidSummarySection';
import {
  basisPointsToPercent,
  Column,
  formatCalculatedPrice,
  isNotNull,
  List,
  percentToBasisPoints,
  StyledLabelBody,
  SubjectText,
  TwoColumns,
  useDebouncedState,
} from '../utils';
import {
  BidCard_BidCard,
  BidCard_BidCard_bid_terms,
} from './__generated__/BidCard';
import BuyerBidSummary from '../BuyerBidSummary';

const DEBOUNCE_DELAY = 1200;
const INPUT_PLACEHOLDER = '100.00';

const StyledCol = styled(Column)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Section = styled.div`
  padding-left: 1rem;
`;

const ListItemStyleProps = {
  overrides: {
    Root: {
      style: {
        backgroundColor: theme.color.cardBackground,
        maxHeight: '40px',
      },
    },
  },
};

function renderListCol(
  termsDocuments: BidCard_BidCard_bid_terms[]
): JSX.Element[] {
  return termsDocuments.map((doc) => {
    const { name, documentSizeBytes, documentUrl } = doc;
    return (
      <ListItem key={name} {...ListItemStyleProps}>
        <ListItemLabel>
          <StyledLabelBody>
            {`${name} - ${prettyBytes(documentSizeBytes)}`}
            <a target="blank" href={documentUrl}>
              <FontAwesomeIcon icon={faFileDownload} />
            </a>
          </StyledLabelBody>
        </ListItemLabel>
      </ListItem>
    );
  });
}

function renderList(
  termsDocuments: BidCard_BidCard_bid_terms[]
): JSX.Element[] {
  const halfListLength = Math.ceil(termsDocuments.length / 2);
  return [
    termsDocuments.slice(0, halfListLength),
    termsDocuments.slice(halfListLength),
  ].map((halfDocs, index) => (
    <List key={halfDocs[0]?.name ?? index}>{renderListCol(halfDocs)}</List>
  ));
}

type Props = {
  dealId: string;
} & BidCard_BidCard;

const BidCardBody = (props: Props): JSX.Element => {
  const [withCounteroffer, setWithCounteroffer] = useState<boolean>(false);
  const [savedCounteroffer, setSavedCounteroffer] = useState<boolean>(
    props.bid.seller_bid_basis_points != null
  );
  const [sellerBidPercent, dSellerBidPercent, setSellerBidPercent] =
    useDebouncedState<string>(
      basisPointsToPercent(props.bid.seller_bid_basis_points),
      DEBOUNCE_DELAY
    );
  const [calculateBidPrice, { data }] = useLazyQuery<
    CalculateBidPrice,
    CalculateBidPriceVariables
  >(CALCULATE_BID_PRICE);
  const [rejectBid] = useMutation<RejectBid, RejectBidVariables>(REJECT_BID);
  const [acceptBid] = useMutation<AcceptBid, AcceptBidVariables>(ACCEPT_BID);
  const [saveSellerBid] = useMutation<
    SaveSellerBid,
    SaveSellerBidVariables
  >(SAVE_SELLER_BID, {
    onCompleted: () => setSavedCounteroffer(true),
  });

  useEffect(() => {
    if (props.counteroffer_details_section) {
      if (dSellerBidPercent.length > 0) {
        const basisPoints = percentToBasisPoints(dSellerBidPercent);
        saveSellerBid({
          variables: {
            input: {
              deal_id: props.dealId,
              seller_bid_basis_points: basisPoints,
            },
          },
        });
        calculateBidPrice({
          variables: {
            input: {
              deal_id: props.dealId,
              bid_basis_points: basisPoints,
            },
          },
        });
      }
    }
  }, [dSellerBidPercent, saveSellerBid, calculateBidPrice, props.dealId]);

  const handleReject = () => {
    rejectBid({
      variables: {
        input: {
          deal_id: props.dealId,
        },
      },
      refetchQueries: [
        {
          query: GET_DEAL_TIMELINE,
          variables: {
            id: props.dealId,
          },
        },
      ],
    });
  };

  const handleAccept = () => {
    acceptBid({
      variables: {
        input: {
          deal_id: props.dealId,
        },
      },
      refetchQueries: [
        {
          query: GET_DEAL_TIMELINE,
          variables: {
            id: props.dealId,
          },
        },
      ],
    });
  };

  const filtersWithIds = props.bid.carve?.map((filter) => ({
    ...filter,
    id: `${filter.field_name}-${filter.operator}`,
  }));

  const hasCarve = props.bid.carve != null && props.bid.carve.length !== 0;
  const carveDetailsSection = hasCarve && (
    <>
      <span>CARVE CRITERIA SPECIFIED</span>
      <Section>
        <FilterContextProvider filters={filtersWithIds}>
          {filtersWithIds != null && (
            <FilterInputs dealId={props.dealId} canEdit={false} />
          )}
        </FilterContextProvider>
      </Section>
    </>
  );

  const contractsSection = props.bid.terms && (
    <ul className='list-none p-0 m-0 flex-1'>
      <span>CONTRACTS</span>
      <div className='flex p-2'>
        {hasCarve
          ? renderList(props.bid.terms)
          : renderListCol(props.bid.terms)}
      </div>
    </ul>
  );

  const {
    currentBalance,
    price: sellerBidPrice,
    rate: sellerRate,
  } = formatCalculatedPrice({
    currentBalanceCents: data?.calculateBidPrice.unpaid_balance,
    priceCents: data?.calculateBidPrice.price,
    rate: data?.calculateBidPrice.rate,
  });

  const counterofferSection = props.can_edit_counteroffer && (
    <>
      <CheckboxWithLabel
        checked={withCounteroffer}
        onClick={() => setWithCounteroffer((state) => !state)}
      >
        Reject with counteroffer
      </CheckboxWithLabel>
      {withCounteroffer && (
        <TwoColumns>
          <Column>
            Define % of UPB
            <Input
              type="number"
              value={sellerBidPercent}
              disabled={!withCounteroffer}
              onChange={(event) => {
                setSavedCounteroffer(false);
                setSellerBidPercent(event.currentTarget.value);
              }}
              placeholder={INPUT_PLACEHOLDER}
              endEnhancer="%"
            />
          </Column>
          <Column>
            <BidSummarySection
              currentBalance={currentBalance}
              bidPrice={sellerBidPrice}
              pricePercent={sellerBidPercent}
              rate={sellerRate}
            />
          </Column>
        </TwoColumns>
      )}
    </>
  );

  const isBidUnsaved = useMemo(
    () => !savedCounteroffer && dSellerBidPercent !== sellerBidPercent,
    [savedCounteroffer, dSellerBidPercent, sellerBidPercent]
  );

  const buttons = props.actions.filter(isNotNull).map((action) => (
    <Button
      onClick={() =>
        action.type === DealActionOptionType.ACCEPT_BID
          ? handleAccept()
          : handleReject()
      }
      disabled={
        withCounteroffer &&
        (action.type === DealActionOptionType.ACCEPT_BID ||
          (action.type === DealActionOptionType.REJECT_BID && isBidUnsaved))
      }
      kind={action.emphasis === Emphasis.STRONG ? KIND.primary : KIND.secondary}
      key={action.title}
    >
      {action.title}
    </Button>
  ));

  return (
    <StyledBody>
      <StyledCol>
        <SubjectText></SubjectText>
        <TwoColumns>
          <StyledCol>
            {carveDetailsSection}
            {props.counteroffer_details_section && (
              <div className="border border-pink-500 rounded-lg p-5 bg-pink-200 text-white">
                <span>SELLER COUNTEROFFER</span>
                <BidSummarySection
                  currentBalance={currentBalance}
                  bidPrice={sellerBidPrice}
                  pricePercent={sellerBidPercent}
                  rate={sellerRate}
                />
              </div>
            )}
            <span>BID</span>
            <BuyerBidSummary
              bid={props.bid}
            />
            {!hasCarve ? contractsSection : <></>}
          </StyledCol>
          <Column>
            <CarveDiffTable
              dealId={props.dealId}
              filteredPool={props.bid.carve_summary}
            />
          </Column>
        </TwoColumns>
        {hasCarve ? contractsSection : <></>}
        {counterofferSection}
        <div>{buttons}</div>
      </StyledCol>
    </StyledBody>
  );
};

export default BidCardBody;
export { default as BidCardFragments } from './fragments';
