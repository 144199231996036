import { useMemo, useState } from 'react';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { DataDisplayTable } from 'common-ui';
import { StratRowType, stratsColumnOrder, stratsColumnsConfig } from './startifications.config';
import type { StratCardContentProps } from './StratCard';
import { useIntl } from 'react-intl';

const commonColumns: ColumnDef<StratRowType>[] = stratsColumnOrder.map((field) => {
  const config = stratsColumnsConfig[field];
  return {
    header: config.header,
    accessorKey: field,
    cell: (cellContext) =>
      config.display(cellContext.getValue() as number | null),
    meta: {
      alignment: 'right',
      numeric: true,
    },
    enableSorting: true,
    enableMultiSort: false,
  };
});

export function StratTable(props: StratCardContentProps) {
  const intl = useIntl();

  const [sorting, setSorting] = useState<SortingState>([]);

  const columns: ColumnDef<StratRowType>[] = useMemo(() => {
    return [
      {
        accessorKey: 'key',
        header: props.config.label,
        cell: (cellContext) =>
          props.config.formatKey(
            intl,
            props.assetClass,
            cellContext.getValue() as string
          ),
        meta: {
          alignment: 'right',
          numeric: true,
        },
        enableSorting: true,
        enableMultiSort: false,
      },
      ...commonColumns,
    ];
  }, [intl, props.assetClass, props.config]);

  const onSortingChanged = (newSorting: SortingState) => {
    setSorting(newSorting);
  };

  return (
    // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
    <DataDisplayTable data={props.data} columns={columns} noDataMessage="NO DATA" sorting={{ state: sorting, onSortingChanged: handleSortingChange }}/>
  );
}
