import React from 'react';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { MARK_NOTIFICATION_READ_MUTATION } from 'mutation/notifications';
import { GET_NOTIFICATIONS_QUERY } from 'query/notifications';
import { MarkNotificationRead, MarkNotificationReadVariables } from 'mutation/__generated__/MarkNotificationRead';
import { GetUserNotifications_userNotifications_notifications as Notification } from 'query/__generated__/GetUserNotifications';

const NotificationsDropdown: React.FC<{ notifications: Notification[], loading: boolean, className?: string }> = ({ notifications, loading, className }) => {
  const [updateNotificationStatus] = useMutation<MarkNotificationRead, MarkNotificationReadVariables>(MARK_NOTIFICATION_READ_MUTATION, {
    refetchQueries: [{ query: GET_NOTIFICATIONS_QUERY }],
  });

  const sortedNotifications = notifications.sort((a, b) => {
    return Number(b.notificationMetadata.createdAt) - Number(a.notificationMetadata.createdAt);
  });

  const handleDismissNotification = (notificationID: string) => {
    updateNotificationStatus({ variables: { requestData: { notificationID } } });
  };

  const handleNotificationClick = (notificationID: string, readAt: number | null) => {
    if (!readAt || readAt < 0) {
      handleDismissNotification(notificationID);
    }
  };

  if (loading && (!notifications || !notifications.length)) {
    return <NotificationsSkeleton className={className} />;
  } else if (!notifications || !notifications.length) {
    return <>No Notifications</>;
  }

  return (
    <div className={`shadow-lg rounded-lg p-4 px-0 pt-0 overflow-y-auto font-heebo ${className}`}>
      {sortedNotifications.map((notification) => {
        const createdAt = Number(notification.notificationMetadata.createdAt);
        const readAt = Number(notification.notificationMetadata.readAt);

        return (
          <div key={notification.notificationMetadata.notificationID}
            className={`bg-[#171717] border-b-2 border-grey-800 ${readAt ? '' : 'border-l-2 border-l-pink-400'} p-3 last:mb-0 flex items-end justify-between`}
            onClick={() => handleNotificationClick(notification.notificationMetadata.notificationID, readAt)}
          >
            <div className='pr-2 w-full'> 
              <div className="text-xs font-medium text-gray-400 flex items-center">
                {DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
                <div className='py-[6px] px-[16px] text-sm font-medium'>
                  {readAt ? <></> : <div className='w-2 h-2 bg-pink-400 rounded-full' /> }
                </div>
              </div>
              <p className={`text-white [&>a]:px-1 [&>a]rounded [&>a]bg-pink-600 [&>a]hover:scale-105 [&>a]active:scale-100`} dangerouslySetInnerHTML={{ __html: notification.content }}></p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const NotificationsSkeleton = ({ className }: { className?: string }) => {
  return (
    <div className={`shadow-lg rounded-lg p-4 px-0 pt-0 overflow-y-auto ${className}`} >
      {[...Array(3)].map((_, index) => (
        <div key={index} className="bg-background-canvas border-b-2 border-grey-800 p-3 last:mb-0 flex items-end justify-between">
          <div className='pr-2 w-full'>
            <div className="text-xs font-medium text-gray-400 flex items-center animate-pulse">
              <div className='w-16 h-4 bg-slate-700 rounded mb-2'></div>
            </div>
            <div className="text-white bg-slate-700 h-4 w-full mb-2 rounded animate-pulse"></div>
            <div className="text-white bg-slate-700 h-4 w-1/2 mb-2 rounded animate-pulse"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationsDropdown;
