import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  GetSellerListings_user_company_listings_UserCompanyListing as Listing,
  GetSellerListings_user_company_listings_UserCompanyListing_deals as Deals,
  GetSellerListings_user_company_listings_UserCompanyListing_deals_state,
} from './__generated__/GetSellerListings';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  formatCentsToDollars,
  toPercentage,
} from 'features/pages/marketplace/BiddingAndPricing/formatting';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { styled, theme } from 'style/ORSNNTheme';
import { match } from 'functions/FunctionalMatch';
import { DealBlocker, DealStatus } from '__generated__/globalTypes';
import { Link } from 'react-router-dom';
import { FIELD_CONFIG } from 'configs/columns';

type DealWithRole = Deals & { role: DealBlocker };

function isListing(original: Listing | Deals): original is Listing {
  return 'deals' in original;
}

const Icons = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & > * {
    margin-right: 5px;
  }
`;
const EnvelopeBackground = styled.span`
  display: block;
  background-color: ${theme.color.warningSubtle};
  padding-left: 5px;
  padding-right: 5px;
`;

type RowIconsProps = {
  row: Row<Listing | DealWithRole>;
}

const RowIcons = (props: RowIconsProps) => {
  const isExpanded = props.row.getIsExpanded();
  const icon = isExpanded ? faChevronDown : faChevronRight;
  const original = props.row.original;
  const hasNewMessages = (() => {
    if (isListing(original)) {
      return original.deals.some((deal) => deal.hasNewMessages);
    } else {
      return original.hasNewMessages;
    }
  })();

  return (
    <Icons>
      {hasNewMessages
        ? <EnvelopeBackground>
            <FontAwesomeIcon icon={faEnvelope} color={hasNewMessages ? theme.color.warningDefault : theme.color.warningDefault} />
          </EnvelopeBackground>
        : <></>
      }
      {props.row.getCanExpand() && <FontAwesomeIcon icon={icon} />}
    </Icons>
  );
};

const StatusColors = {
  UserAction: theme.color.warningDefault,
  CounterpartyAction: theme.color.grey900,
  Complete: theme.color.successDefault,
  Rejected: theme.color.grey900,
};

function getContrastYIQ(hexColor: string) {
  const r = parseInt(hexColor.substring(1, 2), 16);
  const g = parseInt(hexColor.substring(3, 2), 16);
  const b = parseInt(hexColor.substring(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

type StatusSectionProps = {
  color: string;
};

const StatusSection = styled.span<StatusSectionProps>`
  padding: 1px 8px;
  border-radius: 0.25rem;
  background-color: ${(props) => props.color};
  color: ${(props) => getContrastYIQ(props.color)};
`;

const Tooltip = styled(ReactTooltip)`
  max-width: 20rem;
`;

const LinkWrapper = styled(Link)`
  color: ${theme.color.fgDefault};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
      color: ${theme.color.fgMuted};
    cursor: pointer;
  }
`;

const LoanPoolLink = (props: { row: Row<Listing | DealWithRole> }) => {
  const original = props.row.original;
  if (isListing(original)) {
    const hasOneDeal = original.deals.length === 1;
    const link = hasOneDeal ? `../deal/${original.deals[0].id}` : `../listing/${original.id}`;
    return (
      <LinkWrapper to={link}>
        {original.providedName}
      </LinkWrapper>
    );
  } else if ('listing' in original) {
    return (
      <LinkWrapper to={`../deal/${original.id}`}>
        {original.listing.providedName}
      </LinkWrapper>
    );
  } else {
    return <></>;
  }
};

function createPoolStatusCell<T extends DealBlocker>(dealBlockerType: T) {
  return (props: { row: Row<Listing | DealWithRole> }) => {
    const original = props.row.original;
    const hasOneDeal = isListing(original) && original.deals.length === 1;
    const deal = (hasOneDeal ? original.deals[0] : original) as DealWithRole;
    if (isListing(original) && hasOneDeal || 'listing' in original && original.listing) {
      const statusColor = match<GetSellerListings_user_company_listings_UserCompanyListing_deals_state, string>(deal.state)
        .on(
          (state) => state?.status === DealStatus.COMPLETE,
          () => StatusColors.Complete
        )
        .on(
          (state) => state?.status === DealStatus.REJECTED_INQUIRY,
          () => StatusColors.Rejected
        )
        .on(
          (state) =>
            state?.blocker === deal.role || // TODO adjust this to work for buyers
            state?.blocker === DealBlocker.BOTH,
          () => StatusColors.UserAction
        )
        .otherwise(() => StatusColors.CounterpartyAction);

      return <>
        <StatusSection color={statusColor} data-tooltip-id={deal.id}>
          {deal.state.label}
        </StatusSection>
        <Tooltip id={deal.id}>{deal.state.tooltip}</Tooltip>
      </>;
    } else {
      return <></>;
    }
  };
}

export const activeListingsColumnDefinitions: ColumnDef<Listing | DealWithRole>[] = [
  {
    id: 'icons',
    cell: RowIcons,
    meta: {
      headerNoPill: true,
    },
  },
  {
    accessorFn: (row: Listing | Deals) => {
      if (isListing(row)) {
        return row.providedName;
      } else {
        return row.listing.providedName;
      }
    },
    header: 'Offer Name',
    cell: LoanPoolLink,
  },
  {
    accessorKey: 'lastViewed',
    header: 'Last Viewed',
  },
  {
    accessorKey: 'offered',
    header: 'Offered',
    cell: (props) => {
      return (
        <span>
          <FontAwesomeIcon icon={faCheckCircle} color="#00ff00" />
        </span>
      );
    },
  },
  {
    accessorKey: 'state.label',
    header: 'Offer Status',
    cell: createPoolStatusCell(DealBlocker.SELLER),
  },
  {
    accessorKey: 'createdDate',
    header: 'Date Created',
    cell: (props) => {
      return <span>{FIELD_CONFIG.maturity_date.display(props.getValue())}</span>;
    }
  },
  {
    accessorKey: 'counterparty.name',
    header: 'Counterparty',
  },
  {
    header: 'Asset Class',
    accessorFn: (row: Listing | Deals) => {
      if ('assetClass' in row) {
        return row.assetClass;
      }
      if ('listing' in row && 'assetClass' in row.listing) {
        return row.listing.assetClass;
      }
      // Handle other cases or return a default value
      return undefined;
    },
  },
  {
    accessorKey: 'upb',
    header: 'UPB',
    meta: {
      numeric: true,
    },
    cell: (props) => (
      <span>{formatCentsToDollars(Number(props.getValue()))}</span>
    ),
  },
  {
    accessorKey: 'loan_count',
    header: '# Loans',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_coupon',
    header: 'GWAC',
    cell: (props) => <span>{toPercentage(Number(props.getValue()))}</span>,
  },
  {
    accessorKey: 'wa_loan_age_months',
    header: 'WALA',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_remaining_loan_terms_months',
    header: 'WAM',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_borrower_credit_score',
    header: 'FICO',
    meta: {
      numeric: true,
    },
  },
  {
    accessorKey: 'wa_ltv',
    header: 'LTV',
    cell: (props) => <span>{toPercentage(Number(props.getValue()))}</span>,
    meta: {
      numeric: true,
    },
  },
];
