import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterableField } from '__generated__/globalTypes';
import { Button } from 'baseui/button';
import { DropdownPillComboboxSingleAdd } from 'common-ui';
import ErrorMessage from 'common-ui/form/ErrorMessage';
import { TextInput } from 'common-ui/inputs/Inputs-styled';
import { GetFilterBounds_deal_performance_summary } from 'features/core/filter/__generated__/GetFilterBounds';
import { DisabledOverlay } from 'features/loanTape/fieldMapping/MapRequiredFieldsCard';
import {
  Field,
  Form,
  Formik,
  ErrorMessage as FormikErrorMessage,
} from 'formik';
import { styled } from 'style/ORSNNTheme';
import * as Yup from 'yup';
import { CriteriaLabel, Subheader } from './CreateCarveForm.styles';
import { FilterInput } from './FilterInputs';
import { carveFilterConfigs } from './carveFilterConfigs';
import { CarveSpecification } from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/types';
const SaveCardSection = styled.div`
  div {
    display: flex;
    div {
      flex-grow: 1;
    }
  }
`;

const NameCarveBox = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
`;

/* HACK(kentskinner):
 * This is a hack to get the pill button to look like a regular button.
 * The pill button is not meant to be used in this way, so we are
 * overriding the styles here.
 */
const AddCriteriaButton = styled.div`
  button.pill-icon-button::before {
    content: 'Add Criteria';
  }

  button.pill-icon-button {
    border-radius: 4px;
    width: auto;
    text-size: 12px;
    font-weight: 500;
    line-height: 16;
    color: #bdbdbd;
    background-color: #333333;
    height: 28px;
    padding: 6px 16px;
  }

  button.pill-icon-button--selected {
    background-color: #666666;
  }
`;

export interface Criteria {
  field: FilterableField;
  min?: number | Date | null;
  max?: number | Date | null;
  values?: string[];
}

const CriteriaContainer = styled.div`
  width: 390px;
  margin-bottom: 24px;
`;

const initializeCriteria = (
  field: FilterableField,
  bounds: GetFilterBounds_deal_performance_summary
): Criteria | null => {
  const fieldConfig = carveFilterConfigs[field];

  if (!fieldConfig) {
    console.error(`No field config found for field ${field}`);
    return null;
  }
  if (fieldConfig.type === 'select-multi') {
    return { field, values: [] };
  }
  const { minBoundKey, maxBoundKey } = fieldConfig;

  return {
    field,
    min: bounds[minBoundKey as keyof GetFilterBounds_deal_performance_summary],
    max: bounds[maxBoundKey as keyof GetFilterBounds_deal_performance_summary],
  };
};

const createAvailableMetrics = (
  bounds: GetFilterBounds_deal_performance_summary | undefined
) => {
  if (!bounds) {
    return [];
  }

  return Object.keys(carveFilterConfigs)
    .map((key: string) => {
      const criteria = initializeCriteria(key as FilterableField, bounds);
      if (!criteria) {
        return null; // Skip if no criteria could be initialized
      }

      return {
        label:
          carveFilterConfigs[key as FilterableField]?.labelInDropdown || '',
        value: criteria,
      };
    })
    .filter(Boolean) as { label: string; value: Criteria }[]; // Filter out nulls
};

const validationSchema = Yup.object().shape({
  carveName: Yup.string().required('Carve name is required').notOneOf(['Offer']),
});

export interface CreateCarveFormProps {
  filterBounds: GetFilterBounds_deal_performance_summary | undefined;
  onSaveCarve: (carveName: string, criteria: Criteria[]) => void;
  selectedCarve: CarveSpecification | undefined;
  disabled: boolean;
  onCriteriaChanged: (criteria: Criteria[]) => void;
  isUpdating: boolean;
}

const CreateCarveForm: React.FC<CreateCarveFormProps> = ({
  filterBounds,
  onSaveCarve,
  selectedCarve,
  onCriteriaChanged,
  disabled,
}) => {
  const initialAvailableMetrics = createAvailableMetrics(filterBounds);

  const initialValues = {
    carveName: selectedCarve?.name,
  };

  const criteriaList = selectedCarve?.criteria || [];

  // Filter out the metrics that are already in the criteria list
  const availableMetrics = initialAvailableMetrics.filter((metric) => {
    return !criteriaList.some(
      (criteria) => criteria.field === metric.value.field
    );
  });

  const handleAddCriteria = (newCriteria: Criteria) => {
    const updatedCriteriaList = [...criteriaList, newCriteria];
    onCriteriaChanged(updatedCriteriaList);
  };

  const handleRemoveCriteria = (index: number) => {
    const updatedCriteriaList = criteriaList.filter(
      (_, i) => i !== index
    ) as Criteria[];
    onCriteriaChanged(updatedCriteriaList);
  };

  const handleUpdateCriteria = (index: number, updatedCriteria: Criteria) => {
    const updatedCriteriaList = criteriaList.map((criteria, i) => {
      if (i === index) {
        return updatedCriteria;
      }
      return criteria;
    }) as Criteria[];
    onCriteriaChanged(updatedCriteriaList);
  };

  if (filterBounds === undefined) {
    return <div>Could not find filter bounds</div>;
  }

  //TODO currently when we render a new carve, we render a new version of the input components instead of just moving the
  // slider to the new position. This is because we can't re-initialize the slider in the same way as the name input which uses 
  // formik's enableReinitialize prop. We should figure out how to do this so that the slider doesn't reset every time we switch carves.
  return (
    <div style={{ width: 400 }}>
      <Subheader>CARVE CRITERIA</Subheader>
      <div style={{ position: 'relative' }}>
        {disabled && <DisabledOverlay />}
        {criteriaList.map((criteria, index) => {
          const filterConfig = carveFilterConfigs[criteria.field];
          if (!filterConfig) return null;

          return (
            <CriteriaContainer key={`${selectedCarve?.name}-${criteria.field}-${index}`}>
              <CriteriaLabel>
                {filterConfig.labelAboveFilter}

                <div onClick={() => handleRemoveCriteria(index)}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </CriteriaLabel>
              <FilterInput
                criteria={criteria}
                filterBounds={filterBounds}
                onChange={(criteria) => handleUpdateCriteria(index, criteria)}
              />
            </CriteriaContainer>
          );
        })}

        <AddCriteriaButton>
          <DropdownPillComboboxSingleAdd
            options={availableMetrics}
            onValueSelected={({ value }) => handleAddCriteria(value)}
            buttonLabel="Add a metric to the Pool Summary"
            comboLabel="Search for a metric"
            inputPlaceholder="Enter a metric name..."
            manuLabel="All metrics (Ascending)"
          />
        </AddCriteriaButton>

        <SaveCardSection>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (!values.carveName) {
                return;
              }
              onSaveCarve(values.carveName, criteriaList);
            }}
          >
            {(formikProps) =>
              <Form>
                <CriteriaLabel>Name or Choose Your Carve</CriteriaLabel>
                <NameCarveBox>
                  <Field
                    name="carveName"
                    as={TextInput}
                    placeholder="Enter a name"
                    style={{ lineHeight: 2 }}
                  />
                  <Button size="compact" type="submit" disabled={formikProps.values.carveName === initialValues.carveName}>
                    Save New Carve
                  </Button>
                </NameCarveBox>
                <FormikErrorMessage name="carveName">
                  {(msg) => <ErrorMessage>{msg}</ErrorMessage>}
                </FormikErrorMessage>
              </Form>
            }
          </Formik>
        </SaveCardSection>
      </div>
    </div>
  );
};

export {
  CreateCarveForm
};
