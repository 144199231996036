/**
 * Design here: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=5089-146902&mode=design&t=9HZlSXWSxwj25KKG-0
 */
import styled from 'styled-components';

import mainTaskComplete from '../../../assets/svgs/main-task-complete.svg';
import mainTaskNotStarted from '../../../assets/svgs/main-task-not-started.svg';
import mainTaskPending from '../../../assets/svgs/main-task-pending.svg';
import subTaskComplete from '../../../assets/svgs/subtask-complete.svg';
import { useDealRoomContext } from './DealRoomContext';
import { gql } from '@apollo/client';
import { FC } from 'react';
import { StatusTrackerDeal } from './__generated__/StatusTrackerDeal';
import { ExecutionType, OriginationType, PerformanceClass, ServicingPreference, TimelineStatus } from '__generated__/globalTypes';
import { Tooltip } from 'react-tooltip';
import { InfoBadge } from './InfoBadge';
import {formatCentsToDollars} from './BiddingAndPricing/formatting';

const StatusTrackerFragments = {
  deal: gql`
    fragment StatusTrackerDeal on Deal {
      id
      state {
        status
        label
        tooltip
        blocker
      }
      timeline {
        title
        subject
        status
        calls_to_action {
          title
          card {
            __typename
          }
        }
      }
      listing {
        id
        name
        provided_name
        __typename
        ... on PublicListing {
          execution_type
          servicing_preference
          minimum_carve_cents
          performance_class
          origination_type
        }
        ... on UserCompanyListing {
          execution_type
          servicingPref: servicing_preference # graphql feels weird about the names being same with different type
          minimum_carve_cents
          performance_class
          origination_type
        }
      }
    }
  `,
};

const TaskBase = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-color: #171717;
  position: absolute;
  left: 5px;
  width: 26px;
  height: 26px;

  top: -5px;
`;

const TaskComplete = styled(TaskBase)`
  background-image: url(${mainTaskComplete});
`;

const TaskInProgress = styled(TaskBase)`
  background-image: url(${mainTaskPending});
`;

const TaskNotStarted = styled(TaskBase)`
  background-image: url(${mainTaskNotStarted});
`;

const formatOriginationType = (originationType: OriginationType) => {
  switch (originationType) {
    case OriginationType.DIRECT:
      return 'Direct';
    case OriginationType.INDIRECT:
      return 'Indirect';
    case OriginationType.INDIRECT_FINTECH:
      return 'Indirect Fintech';
    case OriginationType.MIXED:
      return 'Mixed';
    case OriginationType.N_A:
      return 'N/A';
  }
};

const formatExecutionType = (executionType: ExecutionType) => {
  switch (executionType) {
    case ExecutionType.ALL_OR_NOTHING:
      return 'AoN';
    case ExecutionType.BUYER_CHOICE:
      return 'Buyer Choice';
  }
};

const formatServicingPreference = (servicingPreference: ServicingPreference) => {
  switch (servicingPreference) {
    case ServicingPreference.NONE:
      return 'No Preference';
    case ServicingPreference.RELEASED:
      return 'Released';
    case ServicingPreference.RETAINED:
      return 'Retained';
  }
};

const formatPerformanceClass = (performanceClass: PerformanceClass) => {
  switch (performanceClass) {
    case PerformanceClass.NON_PERFORMING:
      return 'Non-Performing';
    case PerformanceClass.PERFORMING:
      return 'Performing';
    case PerformanceClass.SUB_PERFORMING:
      return 'Sub-Performing';
    case PerformanceClass.RE_PERFORMING:
      return 'Re-Performing';
  }
};

type StatusTrackerProps = {
  deal: StatusTrackerDeal | null;
};

const StatusTracker: FC<StatusTrackerProps> = (props: StatusTrackerProps) => {
  const { deal } = props;
  // TODO: use same query to render the cards and these subtasks, then useRef to scroll to the correct card
  const { setCurrentCard } = useDealRoomContext();

  const listing = deal?.listing;
  if (!listing) {
    return <></>;
  }

  const servicing = 'servicing_preference' in listing ? listing.servicing_preference : listing.servicingPref;

  return (
    <div className='relative p-[2px] pt-[18px] px-5 pb-5 bg-background-canvas h-full overflow-auto border border-solid border-[#2b2b2b]'>
      <div className='flex-col flex gap-6'>
        <div className="flex justify-between items-center">
          <div className="text-[18px] text-[#f2f2f2]">{listing.provided_name}</div>
          <span className="text-[#5dc389] text-[14px]" data-tooltip-id={deal?.listing.name}>
            {deal?.state.label}
          </span>
        </div>
        {(listing.__typename === 'PublicListing' || listing.__typename === 'UserCompanyListing') && (
          <div className='flex flex-col'>
            <div className='flex flex-col'>
              <div className='flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1'>
                <div className='text-[12px] text-[#909199]'>Origination Type</div>
                <InfoBadge label={formatOriginationType(listing.origination_type)} />
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1'>
                <div className='text-[12px] text-[#909199]'>Execution</div>
                <InfoBadge label={formatExecutionType(listing.execution_type)} />
              </div>
            </div>
            { servicing && <div className='flex flex-col'>
              <div className='flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1'>
                <div className='text-[12px] text-[#909199]'>Servicing</div>
                <InfoBadge label={formatServicingPreference(servicing)} />
              </div>
            </div> }
            <div className='flex flex-col'>
              <div className='flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1'>
                <div className='text-[12px] text-[#909199]'>Performance</div>
                <InfoBadge label={formatPerformanceClass(listing.performance_class)} />
              </div>
            </div>
            {listing.minimum_carve_cents ? (
              <div className='flex flex-col'>
                <div className='flex flex-row items-center justify-between border-b border-b-slate-500 px-2 py-1'>
                  <div className='text-[12px] text-[#909199]'>Minimum Carve</div>
                  <InfoBadge label={formatCentsToDollars(listing.minimum_carve_cents)} />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div className="relative overflow-auto">
        <div className="absolute top-4 bottom-0 left-[17px] w-[1px] bg-[#2b2b2b]" />
        {deal?.timeline.map((stage) => {
          return (
            <div key={stage.title}>
              <div className="relative my-5 pl-10">
                {stage.status === TimelineStatus.COMPLETE ? (
                  <TaskComplete />
                ) : stage.status === TimelineStatus.PENDING ? (
                  <TaskNotStarted />
                ) : (
                  <TaskInProgress />
                )}
                <div>
                  <span className="text-[14px] text-[#909199]">{stage.title}</span>
                  {stage.status && stage.status !== TimelineStatus.PENDING && (
                    <span className="ml-5 pl-1 text-[#ffcf74] font-['PT_Sans_Narrow'] font-normal text-[12px] border-l-[1.5px] border-[#ffcf74]">
                      {stage.status}
                    </span>
                  )}
                </div>
                <div className="text-[12px] text-[#909199]">{stage.subject}</div>
              </div>
              {stage.calls_to_action?.map((cta) => (
                <div key={cta.title} className="relative my-5 text-[12px] text-[#bbc5d7] pl-10">
                  <div className="absolute left-[12px] top-[1px] w-[10px] h-[10px] bg-center bg-no-repeat bg-background-canvas" style={{ backgroundImage: `url(${subTaskComplete})` }} />
                  <span
                    onClick={
                      cta.card.__typename !== 'PlaceholderCard'
                        ? () => setCurrentCard(cta.title)
                        : undefined
                    }
                    className={`underline ${cta.card.__typename === 'PlaceholderCard' ? 'not-allowed opacity-50 cursor-not-allowed' : 'pointer cursor-pointer'}`}
                  >
                    {cta.title}
                  </span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <Tooltip id={deal?.listing.name}>{deal?.state.tooltip}</Tooltip>
    </div>
  );
};

export default StatusTracker;
export {
  StatusTrackerFragments,
};
