import { BaseButton, SvgIcon } from 'common-ui';
import {
  GetNewsArticles,
  GetNewsArticlesVariables,
} from 'query/__generated__/GetNewsArticles';
import { GET_NEWS_ARTICLES } from 'query/getNewsArticles';
import { useQuery } from '@apollo/client';
import { GetNewsArticles_newsArticles as NewsArticle } from 'query/__generated__/GetNewsArticles';
import { NewsArticleField, NewsSource } from '__generated__/globalTypes';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog, useDialog } from 'common-ui/Dialog';
import ArticleDialog from './ArticleDialog';
import { ContentDiv } from 'common-ui/Dialog.styles';

// Format date as MM.DD.YYYY as shown in Figma: https://www.figma.com/file/urbIZ8Fnnln4Knx1lfo7mV/High-Level-Screens?type=design&node-id=8525-43616&mode=design&t=NQkWzefFekrWhX2g-4
const formatDate = (dateAsString: string) => {
  const formatted = new Date(dateAsString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formatted.replace(/\//g, '.');
};

export const Byline: React.FC<{
  source: NewsSource;
  publication_date: string;
  url: string;
}> = ({ source, publication_date, url }) => (
  <div className='flex justify-start gap-3'>
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
      className='no-underline hover:scale-105 active:scale-100'
      title="Read the full story"
    >
      <span className='bg-white text-info-default rounded-sm px-1 py-1/2 text-xs tracking-tighter'>
        {source.replaceAll('_', ' ')}
      </span>
    </a>
    <span className='flex items-center text-xs'>{formatDate(publication_date)}</span>
  </div>
);

const NewsListItem: React.FC<{
  item: NewsArticle;
  onReadStory: () => void;
}> = ({ item, onReadStory }) => {
  return (
    <div className='bg-background-canvas border-b border-grey-800 rounded-lg p-3 text-sm w-auto flex-col'>
      <Byline
        source={item.news_source}
        publication_date={item.publication_date}
        url={item.url}
      />
      <div className='flex flex-row justify-between w-auto'>
        {item.subject}
      </div>
      <div className='flex justify-end'>
        <a href='#' onClick={onReadStory} className='w-32 text-right flex flex-nowrap gap-1.5 flex-row items-center text-pink-400 hover:scale-105 active:scale-100'>
          READ STORY <SvgIcon name="arrow-circle-right" />
        </a>
      </div>
    </div>
  );
};

const NewsListItemSkeleton: React.FC = () => {
  return (
    <div className='bg-background-canvas border border-grey-800 rounded-lg p-3 text-sm w-auto flex-col gap-1'>
      <div className='flex justify-start gap-3'>
        <span className='bg-slate-400 text-info-default rounded-sm px-1 py-1/2 text-xs tracking-tighter w-16 h-4 animate-pulse' />
        <span className='bg-slate-700 text-info-default rounded-sm px-1 py-1/2 text-xs tracking-tighter w-20 h-4 animate-pulse' />
      </div>
      <div className='flex flex-col justify-between w-auto pt-1 gap-1'>
        <div className='bg-slate-700 h-4 w-full rounded-sm animate-pulse' />
        <div className='bg-slate-700 h-4 w-1/2 rounded-sm animate-pulse' />
      </div>
      <div className='flex justify-end'>
        <div className='w-32 text-right flex flex-nowrap gap-1.5 flex-row items-center text-pink-400 hover:scale-105 active:scale-100'>
          <span className='brightness-50 bg-pink-400 h-4 w-32 rounded-sm animate-pulse' />  
          <span className='brightness-50 bg-pink-400 h-4 w-10 rounded-sm animate-pulse' />
        </div>
      </div>
    </div>
  );
}

const PAGE_SIZE = 12;

const LatestNews: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const [allArticles, setAllArticles] = useState<NewsArticle[]>([]);
  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const dialog = useDialog();

  const { data, error, loading, refetch } = useQuery<
    GetNewsArticles,
    GetNewsArticlesVariables
  >(GET_NEWS_ARTICLES, {
    variables: {
      sort: {
        sort_field: NewsArticleField.publication_date_seconds,
      },
      pagination: {
        page_size: PAGE_SIZE,
        offset: offset,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const loadMoreArticles = () => {
    setOffset((offset) => offset + PAGE_SIZE);
  };

  useEffect(() => {
    if (data) {
      if (data.newsArticles.length < PAGE_SIZE) {
        setHasMore(false);
      }
      setAllArticles((prevArticles) => [...prevArticles, ...data.newsArticles]);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      pagination: {
        page_size: PAGE_SIZE,
        offset: offset,
      },
    });
  }, [offset, refetch]);

  const handleReadStory = (index: number) => {
    setCurrentArticleIndex(index);
    dialog.openDialog();
  };

  const currentArticle = allArticles[currentArticleIndex];

  const handleNavigateArticle = (direction: 'next' | 'previous') => {
    if (direction === 'next' && currentArticleIndex < allArticles.length - 1) {
      setCurrentArticleIndex((prevIndex) => prevIndex + 1);
    }

    if (direction === 'previous' && currentArticleIndex > 0) {
      setCurrentArticleIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <>
      <h2 className='font-heebo font-medium text-lg text-white'>Market News</h2>
      <div className='max-h-[100%] pb-[40px] pt-5 overflow-y-auto'>
        <div className='flex flex-col overflow-auto'>
          {allArticles.length === 0 ?  (
            <>
              <NewsListItemSkeleton />
              <NewsListItemSkeleton />
              <NewsListItemSkeleton />
            </>
          ) : allArticles.map((item, index) => {
            return (
              <NewsListItem
                key={index}
                item={item}
                onReadStory={() => handleReadStory(index)}
              />
            );
          })}
          {hasMore ? (
            <BaseButton
              type="secondary"
              size="small"
              label="View More"
              onClick={loadMoreArticles}
            >
              See More Articles
            </BaseButton>
          ) : (
            <div style={{ margin: 10 }}>No more articles available</div>
          )}
          {loading && <div>Loading news articles...</div>}
          {error && <div>Error loading news articles: {error.message}.</div>}
        </div>

        <Dialog dialog={dialog} contentComponent={ArticleDialogContentDiv}>
          <ArticleDialog
            closeDialog={dialog.closeDialog}
            currentArticle={currentArticle}
            currentArticleIndex={currentArticleIndex}
            allArticles={allArticles}
            hasMore={hasMore}
            loadMoreArticles={loadMoreArticles}
            handleNavigateArticle={handleNavigateArticle}
          />
        </Dialog>
      </div>
    </>
  );
};

export { LatestNews };

const ArticleDialogContentDiv = styled(ContentDiv)`
  background-color: rgba(19, 19, 22, 1);
  border: 1px solid ${(props) => props.theme.color.accentEmphasis};
  border-radius: 8px;
  max-width: 95vw;
  max-height: 95vh;
  width: 1000px;
  height: 800px;
  position: relative;
`;

