import { gql } from '@apollo/client';

const LoanDatatableFragments = {
  loan: gql`
    fragment LoanDatatableLoan on Loan {
      __typename
      id
      account_id
      borrower_credit_score
      company {
        id
      }
      current_balance_cents
      original_balance_cents
      ltv
      interest_rate
      servicing_rate
      first_payment_date
      maturity_date
      age_months
      remaining_loan_terms_months
      cra
      listing {
        id
        name
        ... on UserCompanyListing {
          provided_name
        }
      }
      ... on AutoLoan {
        auto_original_value_cents
        auto_vin
        dti
      }
      ... on HomeLoan {
        dti
        occupancy
        purpose
        irap_months
        rap_months
        periodic_cap_rate
        lifetime_cap_rate
        lifetime_floor_rate
        interest_only
        io_period_months
        arm_margin
        arm_index
        arm_index_rate
      }
      last_updated_date
    }
  `,
  performanceSummary: gql`
    fragment LoanDatatablePerformanceSummary on PerformanceSummary {
      loanCount: loan_count
    }
  `,
};

export default LoanDatatableFragments;
