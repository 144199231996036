import { usePopout, FloatingPortal } from '../hooks/usePopout';
import {PillButton} from '../Buttons';
import { styled } from 'style/ORSNNTheme';
import RangeSliderV2 from './RangeSliderV2';

const StyledPopoutContainer = styled.div`
  height: 90px;
  width: 298px;
  border-radius: 8px;
  border-color: ${(props) => props.theme.color.brandMainPink};
  border-style: solid;
  padding: 16px 16px 22px 16px;
  background-color: ${(props) => props.theme.color.cardBackground};
`;

export function RangePillButton(props: {
  min: number;
  max: number;
  name: string;
  initialValue: [number, number];
  onChange?: (values: [number, number]) => void;
  iconName: string;
  onIconClick?: () => void;
  displayPercentage?: boolean;
  integer?: boolean;
  decimal?: boolean;
}) {
  const { refs, getFloatingProps, getReferenceProps, isOpen, floatingStyles } =
    usePopout({ offset: 2 });

  const value = props.displayPercentage ?
    `${props.initialValue[0]}% to ${props.initialValue[1]}%` :
    `${props.initialValue[0]} to ${props.initialValue[1]}`;

  return (
    <>
      <PillButton
        {...getReferenceProps()}
        ref={refs.setReference}
        description={props.name}
        value={value}
        iconName={props.iconName || 'pill-close'}
        iconOnClick={props.onIconClick}
      />

      {isOpen ? (
        <FloatingPortal>
          <StyledPopoutContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <RangeSliderV2
              defaultValue={props.initialValue}
              min={props.min}
              max={props.max}
              onChange={(sliderValues) =>
                props.onChange?.([sliderValues[0], sliderValues[1]])
              }
              displayPercentage={props.displayPercentage}
              integer={props.integer}
              decimal={props.decimal}
            />
            {/* <RangeSlider 
              defaultValue={props.initialValue} 
              min={props.min} 
              max={props.max} 
              onChange={(sliderValues) => props.onChange?.([sliderValues[0], sliderValues[1]])}
              withTooltip={true}
            /> */}
          </StyledPopoutContainer>
        </FloatingPortal>
      ) : null}
    </>
  );
}