import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { InProgressOverlay } from './InProgressOverlay';
import { useTapeUploadContext } from './loanTapeUploader/TapeUploadContext';
import { FileState } from '__generated__/globalTypes';
import { useMapHeadersToFields } from './hooks/useMapHeadersToFields';
import { FieldsMap } from './fieldMapping/MapRequiredFieldsCard';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { DialogActions, StyledHeading } from 'common-ui/Dialog.styles';
import { BaseButton } from 'common-ui';

interface MapFieldsOperationContextProps {
  startMapFields: (fileId: string, fieldMap: FieldsMap) => void;
}

const MapFieldsOperationContext =
  createContext<MapFieldsOperationContextProps | null>(null);

interface MapFieldsOperationWrapperProps {
  fileState: FileState;
  isStarted: boolean;
  setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * HACK (kentskinner)
 * Emergency fix for RC-1. Delete either this or MapFieldsOperationProvider soon.
 */
export const MapFieldsOperationWrapper: React.FC<
  MapFieldsOperationWrapperProps
> = ({ fileState, isStarted, setIsStarted }) => {
  const [progress, setProgress] = useState<number>(0);
  const [description, setDescription] = useState<string>('Initializing...');
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('An error occurred');

  useEffect(() => {
    switch (fileState) {
      case FileState.NEEDS_MAPPING:
        setProgress(0);
        setDescription('Starting...');
        break;

      case FileState.READY_TO_PROCESS:
        setProgress(10);
        setDescription('Mapping fields');
        break;

      case FileState.PROCESSING:
        setProgress(20);
        setDescription('Mapping fields');
        break;

      case FileState.PROCESSED:
        setProgress(100);
        setDescription('Fields mapped.');
        setIsStarted(false);
        break;

      case FileState.ERROR:
        setErrorMessage('An error occurred while mapping fields');
        setIsError(true);
        break;

      case FileState.ERROR_PASSWORD:
        setErrorMessage('This file is password protected');
        setIsError(true);
        break;

      default:
        if (isStarted) {
          console.log('unexpected error in mapping', fileState);
          setErrorMessage('Unexpected error occurred');
          setIsError(true);
        }
        break;
    }
  }, [fileState]);

  const reset = () => {
    setIsStarted(false);
    setIsError(false);
  };

  return (
    <MapFieldsOperation
      isStarted={isStarted}
      setIsStarted={setIsStarted}
      progress={progress}
      description={description}
      isError={isError}
      errorMessage={errorMessage}
      reset={reset}
    />
  );
};

const MapFieldsOperationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { selectedFile, setSelectedFileId } = useTapeUploadContext();
  const { mapHeadersToFields } = useMapHeadersToFields();
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [description, setDescription] = useState<string>('Initializing...');
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('An error occurred');

  useEffect(() => {
    if (selectedFile) {
      switch (selectedFile.state) {
        case FileState.NEEDS_MAPPING:
          setProgress(0);
          setDescription('Starting...');
          break;

        case FileState.READY_TO_PROCESS:
          setProgress(10);
          setDescription('Mapping fields');
          break;

        case FileState.PROCESSING:
          setProgress(20);
          setDescription('Mapping fields');
          break;

        case FileState.PROCESSED:
          setProgress(100);
          setDescription('Fields mapped.');
          setIsStarted(false);
          break;

        case FileState.ERROR:
          setErrorMessage('An error occurred while mapping fields');
          setIsError(true);
          break;

        case FileState.ERROR_PASSWORD:
          setErrorMessage('This file is password protected');
          setIsError(true);
          break;

        default:
          if (isStarted) {
            console.log('unexpected error in mapping', selectedFile.state);
            setErrorMessage('An unexpected error occurred');
            setIsError(true);
          }
          break;
      }
    }
  }, [isStarted, selectedFile]);

  const startMapFields = async (fileId: string, fieldMap: FieldsMap) => {
    setIsStarted(true);
    await mapHeadersToFields(fileId, fieldMap);
  };

  const reset = () => {
    setSelectedFileId(undefined);
    setIsStarted(false);
    setIsError(false);
  };

  return (
    <MapFieldsOperationContext.Provider value={{ startMapFields }}>
      {children}
      <MapFieldsOperation
        isStarted={isStarted}
        setIsStarted={setIsStarted}
        progress={progress}
        description={description}
        isError={isError}
        errorMessage={errorMessage}
        reset={reset}
      />
    </MapFieldsOperationContext.Provider>
  );
};

const useMapFieldsOperation = () => {
  const context = useContext(MapFieldsOperationContext);
  if (!context) {
    throw new Error(
      'useMapFieldsOperation must be used within MapFieldsOperationProvider'
    );
  }
  return context;
};

const MapFieldsOperation: React.FC<{
  isStarted: boolean;
  setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
  progress: number;
  description: string;
  isError: boolean;
  errorMessage: string;
  reset: () => void;
}> = ({
  isStarted,
  setIsStarted,
  progress,
  description,
  isError,
  reset,
  errorMessage,
}) => {
  return (
    <>
      <InProgressOverlay
        title="Mapping In Progress"
        description={description}
        open={isStarted}
        cancel={() => setIsStarted(false)}
        onSuccessClicked={() => setIsStarted(false)}
        progress={progress}
      />
      {isError && (
        <ErrorDialog onErrorDialogClose={reset} errorMessage={errorMessage} />
      )}
    </>
  );
};

interface ErrorDialogProps {
  onErrorDialogClose: () => void;
  errorMessage: string;
}

const ErrorDialog = ({
  onErrorDialogClose,
  errorMessage,
}: ErrorDialogProps) => {
  const dialog = useDialog();
  useEffect(() => {
    dialog.setIsOpen(true);
  }, [dialog]);
  return (
    <Dialog dialog={dialog}>
      <StyledHeading>Upload Error</StyledHeading>
      <div>{errorMessage}</div>
      <DialogActions>
        <BaseButton size="medium" label="cancel" onClick={onErrorDialogClose}>
          OK
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
};

export { MapFieldsOperationProvider, useMapFieldsOperation };
