import { Subheader } from 'features/pages/marketplace/TradeCarveManager/TradeCarveManager.styles';
import { Tab, Tabs } from 'common-ui/Tabs';
import { Note } from './DealDocumentTypes';
import { SelectLoansProps, SelectLoansTab } from './SelectLoansTab';
import { ReviewDiligenceProps, ReviewDiligenceTab } from './ReviewDiligenceTab';
import { DealRole } from '__generated__/globalTypes';
import { useState } from 'react';

export type LoanNotesMap = {
  [loanId: string]: Note[];
};

export interface DiligenceProps {
  selectLoansProps: SelectLoansProps;
  reviewDiligenceProps: ReviewDiligenceProps;
  role: DealRole;
}

export const Diligence = ({
  selectLoansProps,
  reviewDiligenceProps,
  role,
}: DiligenceProps) => {
  const isSeller = role === DealRole.SELLER;
  const [activeTab, setActiveTab] = useState(0);

  const switchToReviewDiligence = () => {
    setActiveTab(1);
  };

  const renderContent = () => {
    if (isSeller) {
      return <ReviewDiligenceTab {...reviewDiligenceProps} />;
    }

    return (
      <>
        <Subheader>Manage diligence</Subheader>
        <Tabs activeTab={activeTab} onTabChange={setActiveTab}>
          <Tab label="Select Loans">
            <SelectLoansTab
              {...selectLoansProps}
              switchToReviewDiligence={switchToReviewDiligence}
            />
          </Tab>
          <Tab label="Review Diligence">
            <ReviewDiligenceTab {...reviewDiligenceProps} />
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <div className='text-slate-200'>
      <div className='flex flex-col gap-6'>
        {renderContent()}
      </div>
    </div>
  );
};
